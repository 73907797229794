import { getHelpAllApi, getHelpDetailApi } from '@/server'
import type { HelpAllResponse, HelpDetailResponse } from '@/server/types'
import { LOAD_STATE } from '~/shared/types'

export function logicHelp() {
  const state = ref(LOAD_STATE.INIT)
  const data = ref<HelpAllResponse[]>([])

  async function getAllHelp() {
    const res = await useLoadState({
      state,
      key: '相关帮助文档',
      fn: getHelpAllApi,
    })
    if (res && res.data)
      data.value = res.data.sort((a, b) => a.sort - b.sort)
  }

  return {
    data,
    getAllHelp,
  }
}

export function logicHelpDetail() {
  const state = ref(LOAD_STATE.INIT)
  const data = ref<HelpDetailResponse | null>(null)

  async function getHelp(id: number, options?: { onlyClient?: boolean }) {
    const res = await useLoadState({
      state,
      key: `文档_${id}`,
      fn: () => getHelpDetailApi(id),
      ...(options || {}),
    })
    if (res && res.data)
      data.value = res.data
  }

  return {
    state,
    data,
    getHelp,
  }
}
